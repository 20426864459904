export const GalleryLoading = () => <div className='galleryLoading'>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
    <div className='galleryLoadingItem'></div>
</div>
export default GalleryLoading;